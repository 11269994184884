// Core
import React from "react"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import SplitHeading from "../split-big-heading/split-big-heading"
import ScrollSection from "../locomotiveScroll/scrollSection"
import VideoComponent from "../video/video"
import JsonText from "../_jsonText"

//SVG
import Circle from "./../../../static/images/svg/text-quarter.inline.svg"

// Styles
import styles from "./rich-text.module.scss"

const RichText = props => {
  const { title } = props,
    { align } = props,
    { theme } = props,
    { vimeo } = props,
    { content } = props

  return (
    <React.Fragment>
      <ScrollSection>
        <InView triggerOnce={true} threshold={0}>
          {({ inView, ref, entry }) => (
            <section
              className={`${styles.text} ${
                theme === "Light" ? styles.light : ""
              }`}
              ref={ref}
              inview={inView.toString()}
            >
              <div className="container">
                <div className="cols flex flex--center">
                  <div className="col m12 t6">
                    {title && (
                      <div
                        data-scroll
                        data-scroll-speed={1}
                        className={
                          align === "Center" ? styles.headingCentered : ""
                        }
                      >
                        <SplitHeading classString={styles.heading} h1={true}>
                          {title}
                        </SplitHeading>
                      </div>
                    )}
                    {vimeo && (
                      <div className={styles.videoWrap}>
                        <VideoComponent videoProps={vimeo} controls={true} />
                      </div>
                    )}
                    <JsonText content={content} />
                  </div>
                </div>
              </div>
              <Circle />
            </section>
          )}
        </InView>
      </ScrollSection>
    </React.Fragment>
  )
}

export default RichText
