import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

//Components
import Footer from "../../components/footer/footer"
import NextPage from "../../components/next-page/next-page"
import RichText from "../../components/rich-text/rich-text"
import SEO from "../../components/seo/seo"

const TextTemplate = props => {
  const page = props,
    templateData = page.data.contentfulTextOnlyTemplate

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    console.log()
    const header = () => {
      let type = templateData.theme === "Light" ? "HEADER_DARK" : "HEADER_LIGHT"
      storeDispatch({ type: type })
    }
    header()
  }, [storeDispatch, templateData.theme])

  let nextpageblock

  if (templateData.nextPage) {
    nextpageblock = (
      <NextPage
        heading={templateData.nextPage.heading}
        title={templateData.nextPage.linkTitle}
        url={`/` + templateData.nextPage.page.slug}
      />
    )
  } else {
    nextpageblock = (
      <NextPage
        heading={`Ready To Talk?`}
        title={`Get In Touch`}
        url={``}
        contact={true}
      />
    )
  }

  return (
    <React.Fragment>
      {templateData.seoData && (
        <SEO
          title={templateData.seoData.seoTitle}
          image={templateData.seoData.seoImage}
          description={templateData.seoData.seoDescription}
        />
      )}
      <RichText
        title={templateData.pageTitle}
        align={templateData.headingAlignment}
        theme={templateData.theme}
        vimeo={templateData.vimeoVideoId}
        content={templateData.textContent}
      />
      {nextpageblock}
      <Footer />
    </React.Fragment>
  )
}

export default TextTemplate

export const pageQuery = graphql`
  query textTemplatePageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTextOnlyTemplate(slug: { eq: $slug }) {
      ...textTemplateQuery
    }
  }
`
